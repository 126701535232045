/* src/styles/global.css */

/* Tailwind'in global direktifleri */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    overflow-x: hidden; /* This prevents overflow of content */
}

/* Custom Swiper Pagination */
.custom-pagination .swiper-pagination-bullet {
    @apply bg-black;
    /* Noktalar için siyah arkaplan */
}

.custom-pagination .swiper-pagination-bullet-active {
    @apply bg-black;
    /* Aktif nokta da siyah */
}
.swiper-button-prev,
.swiper-button-next {
    color: black;
    /* Okları siyah yapar */
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    color: gray;
    /* Üzerine gelindiğinde gri olur */
}

.swiper-slide-active {
    transition: transform 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* Yumuşak geçiş */
}

.swiper-slide-active:hover {
    transform: scale(1.1);
    /* Aktif slayt %110 büyüyecek */
    transition: transform 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* Yumuşak büyüme */
}

.card-slide {
    transition: transform 0.7s cubic-bezier(0.25, 0.8, 0.25, 1);
    /* Diğer slaytlara da geçiş efekti */
}

/*Damise*/
@layer components {
    .text-companyInfo {
        @apply h-7 border border-slate-300 p-1 text-sm font-light shadow-md line-clamp-1;
    }
    .heading-companyInfo {
        @apply text-xs font-light text-slate-600;
    }
    .product-card {
        @apply flex h-40 flex-row rounded-xl bg-[#353642] text-white shadow-lg;
    }
    .similarProducts-card {
        @apply rounded-xl border border-l-slate-300 py-4 shadow-lg;
    }
    .product-card_text {
        @apply flex w-60 flex-col gap-1 p-2;
    }
    .filter-button {
        @apply rounded-full border px-4 text-xs transition-all delay-75 duration-150 ease-in-out;
    }
    .default-button {
        @apply rounded-2xl border border-danger px-4 py-2 text-sm text-danger transition-all delay-75 duration-75 ease-in-out hover:bg-danger hover:text-white;
    }
    .secondary-button {
        @apply rounded-lg bg-teal-500 px-6 text-sm text-white hover:bg-teal-600;
    }
    .disabled-btn {
        @apply !border-slate-200  !text-slate-200 hover:!bg-slate-200 hover:!text-white;
    }
    .type-tag {
        @apply rounded-2xl bg-danger px-2 text-xs font-light text-white;
    }
    .custom-dots {
        @apply bottom-[4.5rem] md:bottom-[4.25rem] !important;
    }
    .custom-dots li {
        @apply h-5 w-5 !important;
    }
    .custom-dots li button {
        @apply h-5 w-5 rounded-full border border-solid border-danger bg-white text-xs  text-danger opacity-100  transition-colors !important;
    }
    .custom-dots li.slick-active button {
        @apply bg-danger text-white  !important;
    }
}
